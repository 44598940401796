import React from "react";
import {
  useFetchList,
  useAddToList,
  useSaveToWishList,
} from "./SaveWishListHook";

export const ListComponent = ({ productUpdateArr, productUpdate }) => {
  console.log(productUpdateArr);

  return (
    <>
      <ul className="notificationList">
        {productUpdateArr.map((option, index) => (
          <li
            key={index}
            onClick={() => {
              productUpdate(index);
            }}
            className={option.toBeDisabled ? "disabled" : ""}
          >
            <i
              class={option.icon}
              aria-hidden="true"
              style={{ color: "rgb(254, 138, 67, 1)", fontSize: "20px" }}
            ></i>
            <span>{option.list_name}</span>
          </li>
        ))}
      </ul>
    </>
  );
};
