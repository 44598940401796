import React, { useEffect, useState } from "react";
import { useEditSettings } from "./ThreeDotsApiCallsHook";
import { useUpdateNotification } from "./SaveWishListHook";

const NotificationSettingComponent = ({ product }) => {
  const [isActive, setIsActive] = useState(0);
  const [notificationType, setNotificationType] = useState('SYSTEM');
  const { editSettingsData, getNotificationsOptions } = useEditSettings();
  const { addUpdateNotificationData, updateNotifcation } = useUpdateNotification();

  const [toggles, setToggles] = useState([]);

  const getNotificationsData = (type, notificationType, index) => {
    getNotificationsOptions({ type: type, product_url: product.product_url });
    setIsActive(index);
    setNotificationType(notificationType);
  };

  useEffect(() => {
    getNotificationsData('NOTIFICATION', 'SYSTEM', 0);
  }, []);

  useEffect(() => {
    if (editSettingsData) {
      const initialToggles = editSettingsData.notification_options.map(option => ({
        system: option.is_system_notification_enable,
        email: option.is_email_notification_enable
      }));
      setToggles(initialToggles);
    }
  }, [editSettingsData]);

  const toggleButton = (index, type) => {
    const newToggles = [...toggles];
    newToggles[index][type] = !newToggles[index][type];
    setToggles(newToggles);
  };

  const onOffNotificationOptions = (index, id) => {
    const type = notificationType.toLowerCase();
    toggleButton(index, type);

    // Collect selected notification IDs
    const selectedIds = editSettingsData.notification_options
      .filter((item, idx) => toggles[idx] && toggles[idx][type])
      .map(item => item.notification_id)
      .join(',');

    updateNotifcation({
      product_url: product.product_url,
      notification_id: localStorage.getItem("notificationId") ?JSON.parse(localStorage.getItem("notificationId")):'',
      type: notificationType,
      is_mute_notification: ''
    });
  };
  return (
    <div className="notficationSettingSection">
      <div className="NotifactionBar">
        <div onClick={() => getNotificationsData('NOTIFICATION', 'SYSTEM', 0)}
          style={{
            background: isActive === 0 ? "rgb(254, 138 ,67,1)" : "white",
            color: isActive === 0 ? "white" : "black",
          }}
        >
          Push Notifications
        </div>
        <div onClick={() => getNotificationsData('NOTIFICATION', 'EMAIL', 1)}
          style={{
            background: isActive === 1 ? "rgb(254, 138 ,67,1)" : "white",
            color: isActive === 1 ? "white" : "black",
          }}
        >
          Email Notifications
        </div>
      </div>
      <ul>
        {editSettingsData && editSettingsData.notification_options.map((item, index) => (
          <li key={index}>
            <span>{item.notification_title}</span>
            <div className="NotifactionBar" onClick={() => onOffNotificationOptions(index, item.notification_id)}
              style={{
                background: toggles[index] && toggles[index][notificationType.toLowerCase()] ? '#9D4EDD' : '#b3adad'
              }}
            >
              <div
                style={{
                  width: '9%',
                  height: '31px',
                  margin: '2px 0px',
                  transform: toggles[index] && toggles[index][notificationType.toLowerCase()] ? 'translateX(73%)' : 'translateX(0%)',
                  background: 'white',
                  borderRadius: '50%'
                }}
              ></div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotificationSettingComponent;
