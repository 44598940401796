// useUniqueIdHook.js

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  fetchWishList} from './actions';


const useGetWishList = () => {
  const dispatch = useDispatch();
  const wishListData = useSelector((state) => state.wishListData);
  const loadingWishList = useSelector((state) => state.loadingWishList);
  const wishListError = useSelector((state) => state.wishListError);

 
  const handleGetToWishList = useCallback(() => {
    dispatch(fetchWishList({}));
  }, [dispatch]);

  console.log('wishListData ',wishListData)

  return {
    wishListData,
    loadingWishList,
    handleGetToWishList,
    wishListError
  };
};

export default useGetWishList;
