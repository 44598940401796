import React from "react";


const NoActiveDeals = ({title,storeName})=>{
    return(
        <>
        {title == undefined ? <>
            <div className='noActiveDeals' style={{marginTop:storeName=="Active Coupons" ?'0px':'0px'}}>
      <div className='imgBox'>
        <div></div>
        <div></div>
      </div>
      <div>
        <h3> No active coupons</h3>
        <span>Check out other stores for more deals</span>
      </div>
    </div></> :
            <div className='noActiveDeals' style={{background:"#e8e1d5"}}>
            <div className='imgBox'>
              <div></div>
              <div></div>
            </div>
            <div>
              <h3>Add your favorite stores</h3>
              <span>to access their latest coupons</span>
            </div>
          </div>}
 </>
    )
}

export default NoActiveDeals;