import React from "react";


export const  getDaysLeft =(endDate)=>{
    const currentDate = new Date();
    const endDateObj = new Date(endDate);
    
    // Calculate the difference in time
    const timeDifference = endDateObj.getTime() - currentDate.getTime();
    
    // Convert time difference from milliseconds to days
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24));
    
    return daysLeft;
}