import React, { useEffect, useState } from "react";
import './deleteDeals.css'


const DeleteSavedDeals =({setIsDelete,setIsDeletePopup,message})=>{
const [deleteList, setDeleteList] = useState(false);

const functionToDeleteList = ()=>{
    setDeleteList(true)
}
useEffect(()=>{
    setIsDelete(deleteList)
},[deleteList])

    return(
        <>
          <div
      className="FavListSectionOverlay"
      style={{ display: true ? "flex" : "none",    height: '100vh', justifyContent: 'center' }}  > 
        <div className="deleteContainer">
            <div className="deleteTextContainer">
               <div><i class="fa fa-trash" aria-hidden="true" style={{color: 'rgb(254, 138, 67, 1)',fontSize:'20px'}}></i></div>
                <div>
                    <h3 style={{paddingLeft:'0px'}}>Delete Product</h3>
                    {/* <p>Are you sure you want to delete this product?</p> */}
                    <p>Are you sure you want to reomve this {message} ?</p>
                </div>
                <div  onClick={()=>{setIsDeletePopup(false)}}><i class="fa fa-close" aria-hidden="true" style={{color: 'rgb(254, 138, 67, 1)',fontSize:'20px',position: 'absolute',
    top: '10px',
    right: '9px',cursor:'pointer'}}></i></div>
            </div>
            <div className="deleteBtnsCotainer">
                <button className="deleteBtns" onClick={()=>{functionToDeleteList()}}>Yes</button>
                <button className="deleteBtns" onClick={()=>{setIsDeletePopup(false)}} >No</button>
            </div>
        </div>
        </div>
        </>
    
    )
}

export default DeleteSavedDeals