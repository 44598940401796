// useUniqueIdHook.js

import { useEffect ,useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFavStoreCoupons} from './actions';


export const useFavStoreCoupons = () => {
    const dispatch = useDispatch();
    const fetchFavStoreCouponsData = useSelector((state) => state.fetchFavStoreCouponsData);
    const fetchFavStoreCouponsFailure = useSelector((state) => state.fetchFavStoreCouponsFailure);
    const loadingfetchFavStoreCoupons = useSelector((state) => state.loadingfetchFavStoreCoupons);

 
  const fetchstoreCoupons = useCallback((storeId) => {
    dispatch(fetchFavStoreCoupons({store_id:storeId}))
  }, [dispatch]);

  return {
    fetchFavStoreCouponsData,
    fetchstoreCoupons
  };
};

// export default useFavStoreCoupons;
