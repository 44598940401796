import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
function IframeTestComponent() {
  const [cookieValue, setCookieValue] = useState('');

  useEffect(() => {
    // Creating a new instance of Cookies
    const cookies = new Cookies();
    console.log(cookies)
    // Reading the value of a specific cookie
    const cookieData = cookies.get('phpbb3_3hnc8_sid');
    setCookieValue(cookieData);
  }, []);

  return (
    <div>
      <h1>Cookie Value: {cookieValue}</h1>
    </div>
  );
}

export default IframeTestComponent;