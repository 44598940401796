// reducers.js
import { addUpdateNotifcation, getNotificationsData } from "./CouponApi";
import {
  FETCH_DATA,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_LIST_FAILURE,
  FETCH_SIMILAR_PRODUCT,
  FETCH_SIMILAR_PRODUCT_FAILURE,
  FETCH_SIMILAR_PRODUCT_SUCCESS,
  FETCH_COOKIE,
  FETCH_COOKIE_FAILURE,
  FETCH_COOKIE_SUCCESS,
  FETCH_UNIQUEID,
  FETCH_UNIQUEID_FAILURE,
  FETCH_UNIQUEID_SUCCESS,
  FETCH_WISHLIST,
  FETCH_WISHLIST_FAILURE,
  FETCH_WISHLIST_SUCCESS,
  SAVE_WISHLIST,
  SAVE_WISHLIST_FAILURE,
  SAVE_WISHLIST_SUCCESS,
  GET_STORELIST,
  GET_STORELIST_FAILURE,
  GET_STORELIST_SUCCESS,
  ADD_FAVSTORE,
  ADD_FAVSTORE_FAILURE,
  ADD_FAVSTORE_SUCCESS,
  FETCH_FAVSTORE,
  FETCH_FAVSTORE_FAILURE,
  FETCH_FAVSTORE_SUCCESS,
  CREATE_LIST,
  CREATE_LIST_FAILURE,
  CREATE_LIST_SUCCESS,
  FETCH_LIST,
  FETCH_LIST_FAILURE,
  FETCH_LIST_SUCCESS,
  FETCH_FAVSTORE_COUPONS,
  FETCH_FAVSTORE_COUPONS_SUCCESS,
  FETCH_FAVSTORE_COUPONS_FAILURE,
  REMOVE_FAV_STORE,
  REMOVE_FAV_STORE_SUCCESS,
  REMOVE_FAV_STORE_FAILURE,
  DELETE_LIST,
  DELETE_LIST_FAILURE,
  DELETE_LIST_SUCCESS,
  ADDUPDATE_LIST,
  ADDUPDATE_LIST_FAILURE,
  ADDUPDATE_LIST_SUCCESS,
  ADD_TO_LIST,
  ADD_TO_LIST_FAILURE,
  ADD_TO_LIST_SUCCESS,
  ADD_UPDATE_NOTIFICATION,
  ADD_UPDATE_NOTIFICATION_FAILURE,
  ADD_UPDATE_NOTIFICATION_SUCCESS,
  MARK_AS_READ,
  MARK_AS_READ_FAILURE,MARK_AS_READ_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,

  DELETE_PRODUCTS,DELETE_PRODUCTS_FAILURE,DELETE_PRODUCTS_SUCCESS,
  EDIT_SETTINGS,EDIT_SETTINGS_FAILURE,EDIT_SETTINGS_SUCCESS,
  PRICE_EVOLUTION,PRICE_EVOLUTION_FAILURE,PRICE_EVOLUTION_SUCCESS,
  GET_SIMILAR_PRODUCTS,GET_SIMILAR_PRODUCTS_FAILURE,GET_SIMILAR_PRODUCTS_SUCCESS,
  DELETE_NOTIFICATION,DELETE_NOTIFICATION_FAILURE,DELETE_NOTIFICATION_SUCCESS,
  PRODUCT_REVIEWS,PRODUCT_REVIEWS_FAILURE,PRODUCT_REVIEWS_SUCCESS
  
} from "./actions";

const initialState = {
  CouponData: [],
  couponLoading: false,
  couponError: null,
  productList: [],
  loadingProductList: true,
  errorProductList: null,
  similarProduct: [],
  loadingSimilarProduct: true,
  errorSimilarProduct: null,
  cookieData: "",
  cookieError: null,
  loadingCookie: false,
  uniqueIdData: "",
  uniqueIdError: null,
  loadinguniqueId: false,
  wishListData: "",
  wishListError: null,
  loadingWishList: false,
  savedWishListData: "",
  savedWishListError: null,
  loadingSavedWishList: false,
  getStoreListData: "",
  getStoreListError: null,
  loadingGetStoreList: false,
  addFavStoreData: "",
  addFavStoreError: null,
  loadingaddFavStore: false,
  fetchFavStoreData: "",
  fetchFavStoreError: null,
  loadingfetchFavStore: false,
  createListData: "",
  createListError: null,
  loadingCreateList: false,
  fetchListData: "",
  fetchListError: null,
  loadingfetchList: false,
  fetchFavStoreCouponsData: "",
  fetchFavStoreCouponsError: null,
  loadingfetchFavStoreCoupons: false,
  removeFavStoreData: "",
  removeFavStoreError: null,
  loadingremoveFavStore: false,
  deleteListData: "",
  deleteListError: null,
  loadingDeleteList: false,
  addUpdateData: "",
  addUpdateError: null,
  loadingAddUpdate: false,
  addToListData: "",
  addToListError: null,
  loadingAddToList: false,
  addUpdateNotificationData: "",
  addUpdateNotificationError: null,
  loadingAddUpdateNotification: false,
  
  deleteProductData: "",
  deleteProductError: null,
  loadngDeleteProduct: false,

  markAsReadData: "",
  markAsReadError: null,
  loadingMarkAsRead: false,

  editSettingsData: "",
  editSettingsError: null,
  loadingEditSettings: false,

  priceEvolutionData: "",
  priceEvolutionError: null,
  loadingPriceEvolution: false,

  similarItemsData: "",
  similarItemsError: null,
  loadingsimilarItems: false,

  getNotificationsData: "",
  getNotificationsError: null,
  loadingGetNotifcations: false,

  deleteNotificationData: "",
  deleteNotificationError: null,
  loadingDeleteNotification: false,

  productReviewsData: "",
  productReviewsError: null,
  loadingProductReviews: false,

};



const reducer = (state = initialState, action) => {
  console.log(state);
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
        couponLoading: true,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        couponLoading: false,
        CouponData: action.payload,
        couponError: null,
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        couponLoading: true,
        couponError: action.payload,
      };
    case FETCH_PRODUCT_LIST:
      return {
        ...state,
        loadingProductList: true,
      };
    case FETCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loadingProductList: false,
        productList: action.payload,
        errorProductList: null,
      };
    case FETCH_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        loadingProductList: true,
        errorProductList: action.payload,
      };
    case FETCH_SIMILAR_PRODUCT:
      return {
        ...state,
        loadingSimilarProduct: true,
      };
    case FETCH_SIMILAR_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingSimilarProduct: false,
        similarProduct: action.payload,
        errorSimilarProduct: null,
      };
    case FETCH_SIMILAR_PRODUCT_FAILURE:
      return {
        ...state,
        loadingSimilarProduct: true,
        errorSimilarProduct: action.payload,
      };
    case FETCH_COOKIE:
      return {
        ...state,
        loadingCookie: true,
      };
    case FETCH_COOKIE_SUCCESS:
      return {
        ...state,
        loadingCookie: false,
        cookieData: action.payload,
        cookieError: null,
      };
    case FETCH_COOKIE_FAILURE:
      return {
        ...state,
        loadingCookie: true,
        cookieError: action.payload,
      };
    case FETCH_UNIQUEID:
      return {
        ...state,
        loadinguniqueId: true,
      };
    case FETCH_UNIQUEID_SUCCESS:
      return {
        ...state,
        loadinguniqueId: false,
        uniqueIdData: action.payload,
        uniqueIdError: null,
      };
    case FETCH_UNIQUEID_FAILURE:
      return {
        ...state,
        loadinguniqueId: true,
        uniqueIdError: action.payload,
      };
    case FETCH_WISHLIST:
      return {
        ...state,
        loadingWishList: true,
      };
    case FETCH_WISHLIST_SUCCESS:
      return {
        ...state,
        loadingWishList: false,
        wishListData: action.payload,
        wishListError: null,
      };
    case FETCH_WISHLIST_FAILURE:
      return {
        ...state,
        loadingWishList: true,
        wishListError: action.payload,
      };
    case SAVE_WISHLIST:
      return {
        ...state,
        loadingSavedWishList: true,
      };
    case SAVE_WISHLIST_SUCCESS:
      return {
        ...state,
        loadingSavedWishList: false,
        savedWishListData: action.payload,
        savedWishListError: null,
      };
    case SAVE_WISHLIST_FAILURE:
      return {
        ...state,
        loadingSavedWishList: true,
        savedwishListError: action.payload,
      };
    case GET_STORELIST:
      return {
        ...state,
        loadingGetStoreList: true,
      };
    case GET_STORELIST_SUCCESS:
      return {
        ...state,
        loadingGetStoreList: false,
        getStoreListData: action.payload,
        getStoreListError: null,
      };
    case GET_STORELIST_FAILURE:
      return {
        ...state,
        loadingGetStoreList: true,
        getStoreListError: action.payload,
      };
    case ADD_FAVSTORE:
      return {
        ...state,
        loadingAddFavStore: true,
      };
    case ADD_FAVSTORE_SUCCESS:
      return {
        ...state,
        loadingAddFavStore: false,
        addFavStoreData: action.payload,
        addFavStoreError: null,
      };
    case ADD_FAVSTORE_FAILURE:
      return {
        ...state,
        loadingAddFavStore: true,
        addFavStoreError: action.payload,
      };

    case FETCH_FAVSTORE:
      return {
        ...state,
        loadingfetchFavStore: true,
      };
    case FETCH_FAVSTORE_SUCCESS:
      return {
        ...state,
        loadingfetchFavStore: false,
        fetchFavStoreData: action.payload,
        fetchFavStoreError: null,
      };
    case FETCH_FAVSTORE_FAILURE:
      return {
        ...state,
        loadingfetchFavStore: true,
        fetchFavStoreError: action.payload,
      };

    case CREATE_LIST:
      return {
        ...state,
        loadingCreateList: true,
      };
    case CREATE_LIST_SUCCESS:
      return {
        ...state,
        loadingCreateList: false,
        createListData: action.payload,
        createListError: null,
      };
    case CREATE_LIST_FAILURE:
      return {
        ...state,
        loadingCreateList: true,
        createListError: action.payload,
      };

    case FETCH_LIST:
      return {
        ...state,
        loadingfetchList: true,
      };
    case FETCH_LIST_SUCCESS:
      return {
        ...state,
        loadingfetchList: false,
        fetchListData: action.payload,
        fetchListError: null,
      };
    case FETCH_LIST_FAILURE:
      return {
        ...state,
        loadingfetchList: true,
        fetchListError: action.payload,
      };

    case FETCH_FAVSTORE_COUPONS:
      return {
        ...state,
        loadingfetchFavStoreCoupons: true,
      };
    case FETCH_FAVSTORE_COUPONS_SUCCESS:
      return {
        ...state,
        loadingfetchFavStoreCoupons: false,
        fetchFavStoreCouponsData: action.payload,
        fetchFavStoreCouponsError: null,
      };
    case FETCH_FAVSTORE_COUPONS_FAILURE:
      return {
        ...state,
        loadingfetchFavStoreCoupons: true,
        fetchFavStoreCouponsError: action.payload,
      };

      case REMOVE_FAV_STORE:
        return {
          ...state,
          loadingRemoveFavStore: true,
        };
      case REMOVE_FAV_STORE_SUCCESS:
        return {
          ...state,
          loadingRemoveFavStore: false,
          RemoveFavStoreData: action.payload,
          RemoveFavStoreError: null,
        };
      case REMOVE_FAV_STORE_FAILURE:
        return {
          ...state,
          loadingRemoveFavStore: true,
          RemoveFavStoreError: action.payload,
        };

        case DELETE_LIST:
          return {
            ...state,
            loadingDeleteList: true,
          };
        case DELETE_LIST_SUCCESS:
          return {
            ...state,
            loadingDeleteList: false,
            deleteListData: action.payload,
            deleteListError: null,
          };
        case DELETE_LIST_FAILURE:
          return {
            ...state,
            loadingDeleteList: true,
            deleteListError: action.payload,
          };

          case ADDUPDATE_LIST:
            return {
              ...state,
              loadingAddUpdate: true,
            };
          case ADDUPDATE_LIST_SUCCESS:
            return {
              ...state,
              loadingAddUpdate: false,
              addUpdateData: action.payload,
              addUpdateError: null,
            };
          case ADDUPDATE_LIST_FAILURE:
            return {
              ...state,
              loadingAddUpdate: true,
              addUpdateError: action.payload,
            };


            case ADD_TO_LIST:
              return {
                ...state,
                loadingAddToList: true,
              };
            case ADD_TO_LIST_SUCCESS:
              return {
                ...state,
                loadingAddToList: false,
                addToListData: action.payload,
                addToListError: null,
              };
            case ADD_TO_LIST_FAILURE:
              return {
                ...state,
                loadingAddToList: true,
                addToListError: action.payload,
              };


              case ADD_UPDATE_NOTIFICATION:
                return {
                  ...state,
                  loadingAddUpdateNotification: true,
                };
              case ADD_UPDATE_NOTIFICATION_SUCCESS:
                return {
                  ...state,
                  loadingAddUpdateNotification: false,
                  addUpdateNotificationData: action.payload,
                  addUpdateNotificationError: null,
                };
              case ADD_UPDATE_NOTIFICATION_FAILURE:
                return {
                  ...state,
                  loadingAddUpdateNotification: true,
                  addUpdateNotificationError: action.payload,
                };

                case MARK_AS_READ:
                  return {
                    ...state,
                    loadingMarkAsRead: true,
                  };
                case MARK_AS_READ_SUCCESS:
                  return {
                    ...state,
                    loadingMarkAsRead: false,
                    markAsReadData: action.payload,
                    markAsReadError: null,
                  };
                case MARK_AS_READ_FAILURE:
                  return {
                    ...state,
                    loadingMarkAsRead: true,
                    markAsReadError: action.payload,
                  };


                  case GET_NOTIFICATIONS:
                    return {
                      ...state,
                      loadingGetNotifcations: true,
                    };
                  case GET_NOTIFICATIONS_SUCCESS:
                    return {
                      ...state,
                      loadingGetNotifcations: false,
                      getNotificationsData: action.payload,
                      getNotificationsError: null,
                    };
                  case GET_NOTIFICATIONS_FAILURE:
                    return {
                      ...state,
                      loadingGetNotifcations: true,
                      getNotificationsError: action.payload,
                    };


                    case GET_NOTIFICATIONS:
                      return {
                        ...state,
                        loadingGetNotifcations: true,
                      };
                    case GET_NOTIFICATIONS_SUCCESS:
                      return {
                        ...state,
                        loadingGetNotifcations: false,
                        getNotificationsData: action.payload,
                        getNotificationsError: null,
                      };
                    case GET_NOTIFICATIONS_FAILURE:
                      return {
                        ...state,
                        loadingGetNotifcations: true,
                        getNotificationsError: action.payload,
                      };



                      case DELETE_PRODUCTS:
                        return {
                          ...state,
                          loadngDeleteProduct: true,
                        };
                      case DELETE_PRODUCTS_SUCCESS:
                        return {
                          ...state,
                          loadngDeleteProduct: false,
                          deleteProductData: action.payload,
                          deleteProductError: null,
                        };
                      case DELETE_PRODUCTS_FAILURE:
                        return {
                          ...state,
                          loadngDeleteProduct: true,
                          deleteProductError: action.payload,
                        };


                        
                      case EDIT_SETTINGS:
                        return {
                          ...state,
                          loadingEditSettings: true,
                        };
                      case EDIT_SETTINGS_SUCCESS:
                        return {
                          ...state,
                          loadingEditSettings: false,
                          editSettingsData: action.payload,
                          editSettingsError: null,
                        };
                      case EDIT_SETTINGS_FAILURE:
                        return {
                          ...state,
                          loadingEditSettings: true,
                          editSettingsError: action.payload,
                        };


                        case PRICE_EVOLUTION:
                          return {
                            ...state,
                            loadingPriceEvolution: true,
                          };
                        case PRICE_EVOLUTION_SUCCESS:
                          return {
                            ...state,
                            loadingPriceEvolution: false,
                            priceEvolutionData: action.payload,
                            priceEvolutionError: null,
                          };
                        case PRICE_EVOLUTION_FAILURE:
                          return {
                            ...state,
                            loadingPriceEvolution: true,
                            priceEvolutionError: action.payload,
                          };


                          case GET_SIMILAR_PRODUCTS:
                            return {
                              ...state,
                              loadingSimilarItems: true,
                            };
                          case GET_SIMILAR_PRODUCTS_SUCCESS:
                            return {
                              ...state,
                              loadingSimilarItems: false,
                              SimilarItemsData: action.payload,
                              SimilarItemsError: null,
                            };
                          case GET_SIMILAR_PRODUCTS_FAILURE:
                            return {
                              ...state,
                              loadingSimilarItems: true,
                              SimilarItemsError: action.payload,
                            };



                            case DELETE_NOTIFICATION:
                              return {
                                ...state,
                                loadingDeleteNotification: true,
                              };
                            case DELETE_NOTIFICATION_SUCCESS:
                              return {
                                ...state,
                                loadingDeleteNotification: false,
                                deleteNotificationData: action.payload,
                                deleteNotificationError: null,
                              };
                            case DELETE_NOTIFICATION_FAILURE:
                              return {
                                ...state,
                                loadingDeleteNotification: true,
                                deleteNotificationError: action.payload,
                              };


                              case PRODUCT_REVIEWS:
                                return {
                                  ...state,
                                  loadingProductReviews: true,
                                };
                              case PRODUCT_REVIEWS_SUCCESS:
                                return {
                                  ...state,
                                  loadingProductReviews: false,
                                  productReviewsData: action.payload,
                                  productReviewsError: null,
                                };
                              case PRODUCT_REVIEWS_FAILURE:
                                return {
                                  ...state,
                                  loadingProductReviews: true,
                                  productReviewsError: action.payload,
                                };


  

    default:
      return state;
  }
};

export default reducer;
