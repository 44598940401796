import React, { useEffect, useState } from "react";
import SearchInput from "./SearchInput";
import { useDispatch, useSelector } from "react-redux";
import {
  saveFavStore,
  fetchFavStores,
  getStores,
  removeFavStore,
} from "./actions";
import StoreListComponent from "./storeList";

const FavoriteStoresList = ({
  domain,
  copyToClipboard,
  setFavStore,
  storeList,
  favouriteStoreList,
  fetchFavStoresData,
  height,
  animation,
  borderRadius,
  overlay,
  heading,
  top,
  submitBtn,
  stars,
  storeBox,
  getFavStoreCoupons,
  couponsList,
  activeTab,
  title,
  backgroundColor,
  seeAll,
}) => {
  console.log("favouriteStoreList ", favouriteStoreList);
  const [searchText, setSearchText] = useState("");
  const [websiteList, setWebsiteList] = useState(storeList);
  const [firstLetter, setfirstLetter] = useState("");
  const [favStoreList, setFavStoreList] = useState(favouriteStoreList);
  const [sectionClose, setSectionClose] = useState(false);
  const [isStoreBox, setIsStoreBox] = useState(storeBox);
  const [coupons, setIsCoupons] = useState(couponsList);
  const dispatch = useDispatch();
  const getStoreListData = useSelector((state) => state.getStoreListData);
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    setfirstLetter(value.charAt(0));
    storeBox != undefined ? setIsStoreBox(true) : setIsStoreBox(false);
  };
  const [searchedClicked, setsearchedClicked] = useState(false);
  const fetchSoreFromServerOnSearch = () => {
    // const value = event.target.value;
    // setSearchText(value);
    setsearchedClicked(true);
    dispatch(getStores(searchText));
    setfirstLetter(searchText.charAt(0));
    storeBox != undefined ? setIsStoreBox(true) : setIsStoreBox(false);
  };

  useEffect(() => {
    setIsCoupons(couponsList);
  }, [couponsList]);
  const clearSearchText = () => {
    setSearchText("");
    dispatch(getStores(""));
    setIsCoupons(couponsList);
  };

  const [storeFound, setStoreFound] = useState(false);
  useEffect(() => {
    if (getStoreListData && getStoreListData.data.length > 0) {
      setWebsiteList(getStoreListData.data);
      setStoreFound(true);
    } else {
      console.log("no result found");
      setStoreFound(false);
    }
  }, [getStoreListData]);

  useEffect(() => {
    if (sectionClose == true) {
      setFavStore(false);
    }
  }, [sectionClose]);

  return (
    <div
      className="FavListSectionOverlay"
      style={{
        display: !sectionClose ? "flex" : "none",
        background:
          overlay != undefined && !overlay
            ? "transparent"
            : "rgba(0, 0, 0, 0.15)",
        height: overlay != undefined && !overlay ? "0" : "100%",
      }}
    >
      <div
        className="FavListSection"
        style={{
          padding: "30px",
          display: sectionClose ? "none" : "block",
          zIndex: "1000",
          height: height ? height : "auto",
          animation: animation ? animation : "0.15s moveUp ease-out 1 forwards",
          borderRadius: borderRadius ? borderRadius : "20px 20px 0 0",
          top: top ? top : "",
          // backgroundColor: backgroundColor ? backgroundColor : "#fbfbf8",
          backgroundColor: backgroundColor ? backgroundColor : "#fff",
        }}
      >
        <div
          className="manageFavstoreContainer model-header"
          style={{
            display: heading ? heading : "flex",
            top: top ? top : "",
          }}
        >
          <h2 style={{ textAlign: "center", padding: "0px" }}>
            Manage Favorite Stores
          </h2>

          <div
            className="model-header-icon"
            onClick={() => {
              setSectionClose(true);
            }}
          >
            <i class="fa fa-close" aria-hidden="true"></i>
          </div>
        </div>
        <div style={{ height: "auto " }}>
          {activeTab == undefined ||
          activeTab == "Pouplar Coupons" ||
          activeTab == "Coupons And Deals" ? (
            <SearchInput
              searchText={searchText}
              onSearchChange={handleSearchChange}
              height="43px"
              margin="10px 0px 10px 0px"
              clearSearchText={clearSearchText}
            />
          ) : (
            <>
              {title ? (
                <h3 style={{ padding: "0px 0px 12px 0px" }}>{title}</h3>
              ) : (
                ""
              )}
            </>
          )}

          <StoreListComponent
            domain={domain}
            copyToClipboard={copyToClipboard}
            websitelist={websiteList}
            firstletter={firstLetter}
            searchText={searchText}
            favStorelist={favStoreList}
            setSectionClose={setSectionClose}
            fetchFavStoresData={fetchFavStoresData}
            submitBtn={submitBtn}
            stars={stars}
            isStoreBox={isStoreBox}
            getFavStoreCoupons={getFavStoreCoupons}
            setIsStoreBox={setIsStoreBox}
            coupons={coupons}
            seeAll={seeAll}
          />
        </div>
      </div>
    </div>
  );
};

export default FavoriteStoresList;
