import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchData ,fetchCookie } from './actions';
import './coupons.css';
import Coupons from './Coupons';
import Product from './Product';

const CouponsandSavings = ({ domain, couponPayload ,isCheckoutMode}) => {
  const [CheckoutMode, setisCheckoutMode] = useState(false)
  const [noCoupons, setnoCoupons] = useState(true)
  const [cookies, setCookie] = useState('')
  const [mandateCoupondField] = useState('applyCoupon')
  const  mandateFeatureList = [mandateCoupondField]
  console.log(domain)
  console.log(couponPayload)
  const dispatch = useDispatch();
  const CouponData = useSelector((state) => state.CouponData);
  const couponError = useSelector((state) => state.couponError);
  console.log(CouponData.related_coupons  == undefined)

  useEffect(() => {
    console.log('detch active coupons')
    dispatch(fetchData(couponPayload))

  }, [dispatch, couponPayload]);

  // tocheck mode
  useEffect(() => {
    console.log(isCheckoutMode)
    // if(isCheckoutMode =='checkout' && CouponData != undefined && CouponData.related_coupons != undefined && CouponData.related_coupons.length > 0){
      if(isCheckoutMode =='checkout' ){
      setnoCoupons(false)
    setisCheckoutMode(true)
    console.log(CheckoutMode)
}else{
  setnoCoupons(true)
  setisCheckoutMode(false)
}
  }, [ isCheckoutMode,CouponData]);

 

  useEffect(() => {

    // if (CouponData && domain) {
      // Add event listener to receive messages from the plugin
      const parentWindow = window.parent;
      console.log(domain)
      // Send the message to the parent window
      console.log(CouponData != undefined && CouponData.is_show_apply_button == 1 && CouponData.related_coupons != undefined && CouponData.related_coupons.length > 0)
      if( CouponData != undefined && CouponData.is_show_apply_button == 1 && CouponData.related_coupons != undefined && CouponData.related_coupons.length > 0){
        setnoCoupons(false)
        console.log(CouponData);
    
        function removeDuplicates(data) {
          let uniqueCodes = new Set();
          let uniqueData = [];
          data.forEach(coupon => {
              if (!uniqueCodes.has(coupon.coupon_code)) {
                  uniqueCodes.add(coupon.coupon_code);
                  uniqueData.push(coupon);
              }
          });
          
          return uniqueData;
      }
      
      let uniqueCouponData = removeDuplicates(CouponData.related_coupons);
      console.log(uniqueCouponData);
      let storeImage ='';
      if(CouponData.related_coupons != '' && CouponData.related_coupons[0] !=undefined && CouponData.related_coupons[0].store_logo != undefined)
        storeImage = CouponData.store_img_base_url + CouponData.related_coupons[0].store_logo;
      parentWindow.postMessage({ action: 'recivedCouponsFromIframe', uniqueCouponData,storeImage }, domain);
}
    // }



  }, [CouponData, domain]);

  // when will click on apply coupon will check first coupon is mandate or not 
  const sendCallToPluginForCouponApply = async ()=>{
    const parentWindow = window.parent;
  // if present in mandate list then will check for valid session if not valid send to login 
    console.log(mandateFeatureList)
    let presentInMandateList = mandateFeatureList.includes(mandateCoupondField)
    console.log(presentInMandateList)
    let validSession;
    
    if(presentInMandateList){
      const sessionResponse = await fetchAndValidateSession();
      validSession = sessionResponse; // Assuming fetchAndValidateSession returns true/false
 
    }


    if(!validSession){
      console.log(validSession)
      console.log('if invalid redirect to login page n do not run below console')

      parentWindow.postMessage({ action: 'userSessionExpired' }, domain);
      // // If the cookie is not valid or doesn't exist, redirect to login page
      window.open("https://lannister.deals2buy.in/?source=plugin", "_blank");

      return;
    } 

      console.log('not present in list  or session is valid run the code')
  // if it is not mandate or session is valid  then apply coupon functionality will be called
      if (domain) {
        let codeArr=[]
        if(CouponData != undefined && CouponData.is_show_apply_button == 1 && CouponData.related_coupons != undefined && CouponData.related_coupons.length > 0)
          CouponData.related_coupons.forEach(couponObj=> codeArr.push({'code':couponObj.coupon_code,'code':couponObj.coupon_uuid}))
      console.log('codeArr ',codeArr)
      console.log('codeArr ',codeArr)
      const uniqueCodes = new Set(codeArr.map(item => JSON.stringify(item)));
      codeArr = Array.from(uniqueCodes).map(item => JSON.parse(item));
      console.log(codeArr);
  //     codeArr =  [

  //   {
  //     "code": "CDGD"
  //   },
  //   {
  //     "code": "LT4M-DG6K-6LNN-XPP2"
  //   },
  //   {
  //     "code": "TRUT23"
  //   },
  //   {
  //     "code": "CJCART5"
  //   }
  // ];
  let storeImage = CouponData.store_img_base_url + CouponData.related_coupons[0].store_logo;
        parentWindow.postMessage({ action: 'startApplyingCoupons',coupons:codeArr ,storeImage}, domain);
      
      }
  }
 
  const fetchAndValidateSession = async () => {
    const cookieString = document.cookie;
    console.log('cookieString ', cookieString)
    const cookiesArray = cookieString.split('; ');
    console.log('cookiesArray ', cookiesArray)
  
    for (let cookie of cookiesArray) {
      const [cookieName, cookieValue] = cookie.split('=');
      console.log('cookieName ',cookieName)
      console.log('cookieValue ',cookieValue)
      console.log(cookieName.trim() == 'phpbb3_3hnc8_sid')
      if (cookieName.trim() == 'phpbb3_3hnc8_sid') {
        console.log('call to server for valid session')
        const url = "https://api.deals2buy.in/validateSession.php";
  
        const postData = {
          session_id: cookieValue,
        };
  
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
  
          },
          body: JSON.stringify(postData),
        };
  
        try {
          const response = await fetch(url, options);
          console.log('response ',response)
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          console.log(data);
          if (data.isValidSession) {
            return data.isValidSession;
          }
        } catch (error) {
          console.error("There was a problem with the fetch operation:", error);
        }
        break;
      }
    }
  };
  
  return (
    <>
    {  CheckoutMode   && CouponData != undefined && CouponData.is_show_apply_button == 1 && CouponData.related_coupons != undefined && CouponData.related_coupons.length > 0
    ?
    <div className='coupons_savings'>
    {/* <div className="product_wrapper">
      <Product />
      <div className="graph"></div>
    </div> */}
    <div className="coupons_wrapper">
      {couponError || CouponData.related_coupons == undefined ? 
       '' :
      <div>
         <button className='applyBtn'   style={{ display: CheckoutMode   && CouponData != undefined && CouponData.is_show_apply_button == 1 && CouponData.related_coupons != undefined && CouponData.related_coupons.length > 0? 'inline-block' : 'none' }}  onClick={sendCallToPluginForCouponApply}>Apply Coupons</button>
     
      </div>
     }
     
    </div>
  </div>
  :''}
{/* {true
?
<button className='applyBtn'   style={{ display: true? 'inline-block' : 'none' }}  onClick={sendCallToPluginForCouponApply}>Apply Coupons</button>

:''} */}

    </>
 
  )
}

export default CouponsandSavings
