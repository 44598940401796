import React, { useEffect, useState } from "react";
import Product from "./Product";
import { useGetNotification } from "./ThreeDotsApiCallsHook";
import { handleRedirect } from "./BuildUrl";

const RecentlyViewedCard = ({
  recentData,
  saveProduct,
  addRemoveSavedProduct,
  border,
  threeDots,
  threeDotsClicked,
  domain
}) => {
  const { getNotificationsData, NotificationsDataFromDerver } = useGetNotification();
  const [data, setRecentData] = useState([]);
  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    if (getNotificationsData !== undefined && getNotificationsData.notifications !== undefined && Object.keys(getNotificationsData.notifications).length > 0) {
      const notificationsArray = Object.keys(getNotificationsData.notifications).filter(key => key !== "image_base_url").map(key => getNotificationsData.notifications[key]);
      setRecentData(notificationsArray);
      setBaseUrl(getNotificationsData.notifications.image_base_url);
    }
  }, [getNotificationsData]);

  const getProductUrl = (url) => {
    handleRedirect(url, "", true, domain);
  };
  
  return (
    <>
      <ul className="recentlyViewed" style={{ padding: '14px' }}>
        {data.map((product, index) => (
          <li key={product.id}>
            <img
              src={baseUrl + product.product_image}
              className="images-size"
              style={{ border: border !== undefined ? border : '10px 0 0 10px' }}
              onClick={() => getProductUrl(product.product_url)}
            />
            <div className="productDetail" style={{ background: 'white' }} onClick={() => getProductUrl(product.product_url)}>
              <div className="price">
                <span>{product.product_price}</span>
                <span className="line">0</span>
              </div>
              <div>
                <p className="title">{product.product_name}</p>
              </div>
            </div>
            {threeDots ? (
              <div onClick={() => threeDotsClicked(product)}>
                <i className="fa fa-ellipsis-v threeDots" aria-hidden="true"></i>
              </div>
            ) : ''}
          </li>
        ))}
      </ul>
    </>
  );
}

export default RecentlyViewedCard;
