import React, { useEffect, useState } from "react";
import ProductListContainer from "./productListContainer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CreateNewList from "./createNewList";
import { buildUrl, getStoreName, handleRedirect } from "./BuildUrl";
import useUniqueId from "./useUniqueIdHook";
import RecentlyViewedCard from "./recentlyViewedCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchList } from "./actions";
import { useSaveToWishList, useUpdateNotification } from "./SaveWishListHook";
import DeleteSavedDeals from "./deleteSavedDeals";
import useGetWishList from "./getWishListHook";
import { type } from "@testing-library/user-event/dist/type";
import { useEditSettings } from "./ThreeDotsApiCallsHook";
import EditList from "./EditListComponent";
import SingleProductDisplay from "./singleProductComponent";

const AddComponent = ({
  storedData,
  isCheckoutMode,
  activeProductData,
  domain,
}) => {
  // activeProductData = {
  //   breadcrumb:
  //     "Home\nWomen\nEthnic Wear\nCo-ord Sets\nWomen 2-Piece Co-Ord Set with Insert Pockets",
  //   imageTag:
  //     '<img class="rilrtl-lazy-img img-alignment zoom-cursor rilrtl-lazy-img-loaded" src="https://assets.ajio.com/medias/sys_master/root/20240410/qpyO/66159d6805ac7d77bb003578/-473Wx593H-467241893-green-MODEL.jpg" alt="Product image of Janasya Women 2-Piece Co-Ord Set with Insert Pockets" tabindex="0">',
  //   product_image:
  //     "https://assets.ajio.com/medias/sys_master/root/20240410/qpyO/66159d6805ac7d77bb003578/-473Wx593H-467241893-green-MODEL.jpg",
  //   product_price: "1491",
  //   product_url:
  //     "https://www.ajio.com/janasya-women-2-piece-co-ord-set-with-insert-pockets/p/467241893_green",
  //   product_title: "Women 2-Piece Co-Ord Set with Insert Pockets",
  // };
  const [itemInfoSection, setItemInfoSection] = useState("0");
  const [singleProduct, setSingleProduct] = useState(activeProductData);
  const [saveListid, setSaveListId] = useState("");
  const [saveList, setSaveList] = useState([]);
  const [itemSaved, setItemSaved] = useState(false);
  const [suggestedList, setSuggestedList] = useState([
    { name: "Favourates", desc: "" },
  ]);
  const [recentData, setRecentData] = useState([]);
  const [showSavePoint, setShowSavePoint] = useState(false);
  const [openCreateBox, setOpenCreateBox] = useState(false);
  const [addRemoveSavedProduct, setAddRemoveSavedProduct] = useState(false);

  const dispatch = useDispatch();
  const fetchListData = useSelector((state) => state.fetchListData);
  const fetchListError = useSelector((state) => state.fetchListError);
  const loadingfetchList = useSelector((state) => state.loadingfetchList);

  const { uniqueIdData } = useUniqueId();
  const [uuid, setUuid] = useState("");
  // const platform =getStoreName(domain)

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  useEffect(() => {
    // localStorage.setItem("selectedLists", JSON.stringify([]));
    localStorage.setItem("notificationId", JSON.stringify([]));
  }, []);

  const { savedeals } = useSaveToWishList();
  const { savedWishListData, handleSaveToWishList } = useSaveToWishList();
  // const platform =getStoreName(domain)

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  const getProductUrl = (url) => {
    // const dealUrl = buildUrl(uuid, url, platform);
    console.log("domain ", domain);
    handleRedirect(url, "", true, domain);
  };

  const onListSelect = (id) => {
    if (id != "") {
      setSaveListId(id);
      console.log("selected saveListid ", id);
      console.log("saveListid ", saveListid);
    }
  };

  const openCreatNewListBox = () => {
    setOpenCreateBox(true);
  };
  const closeCreatListBox = () => {
    setOpenCreateBox(false);
  };

  const { addUpdateNotificationData, updateNotifcation } =
    useUpdateNotification();
  const addUpdateNotification = (data) => {
    updateNotifcation(data);
  };

  // update the list in which wil lsaved fav product
  const updateProductList = (listObj) => {
    console.log("listObj ", listObj);
  };

  useEffect(() => {
    setSaveList(setSaveList(fetchListData.data));
  }, [fetchListData]);

  //  let singleProduct = activeProductData;
  console.log(activeProductData);
  const title = singleProduct.product_title;
  // Split the title into an array of words using split() method
  const wordsArray = title ? title.split(" ") : "";
  // Get the first word from the array
  const firstWord = wordsArray[0];

  const saveProduct = async (data, index) => {
    console.log(data);
    setAddRemoveSavedProduct(addRemoveSavedProduct == index ? null : index);
    setItemSaved(true);

    if (Object.keys(data).length != 0) {
      // save in localstorage
      // data["product_listName"] = saveListid.list_name ? saveListid.list_name : "";
      // data["product_listDescription"] = saveListid.list_description ? saveListid.list_description : "";
      // const currentDate = new Date().toISOString();
      // data["savedDate"] = currentDate;
      // data['product'] = activeProductData;
      // console.log("newData ", data);
      // setSingleProduct(data);
      // let newData = JSON.parse(localStorage.getItem("savedProductData")) || [];
      // newData.push(data);

      // localStorage.setItem("savedProductData", JSON.stringify(newData));
      console.log("saveListid ", saveListid);
      let obj = {
        product_title: data.product_title,
        product_category: data.breadcrumb,
        product_image: data.imgSrc,
        product_price: data.price,
        product_url: data.product_url,
        // list_id: localStorage.getItem("selectedLists")
        //   ? JSON.parse(localStorage.getItem("selectedLists")).join(",")
        //   : "",
        list_id: saveListid,
        uuid_type: "deal",
        uuid: "",
        type: alreadySaved ? "UPDATE" : "ADD",
        notification_id: localStorage.getItem("notificationId")
          ? JSON.parse(localStorage.getItem("notificationId"))
          : "",
      };

      console.log(obj);
      handleSaveToWishList(obj);
    }
  };

  console.log("isCheckoutMode ", isCheckoutMode);

  useEffect(() => {
    // if (CouponData && domain) {
    // Add event listener to receive messages from the plugin
    const parentWindow = window.parent;
    console.log(domain);
    const startTime = performance.now();
    parentWindow.postMessage(
      { action: "recentUserHistory", startTime },
      domain
    );
  }, [domain]);
  window.addEventListener("message", (event) => {
    const receivedData = event.data;

    // console.log(receivedData);
    if (receivedData.action == "recentHistoryData") {
      const endTime = performance.now();
      const totalElapsedTime = endTime - event.data.startTime;
      const steps = event.data.steps;
      console.log(
        `Total time taken for round trip: ${totalElapsedTime.toFixed(2)} ms`
      );
      console.log(`Steps timings:`, steps);

      const uniqueTitles = Array.from(
        new Set(receivedData.data.map((item) => item.product_title))
      );

      // Map unique URLs to their respective objects
      const uniqueProducts = uniqueTitles.map((title) =>
        receivedData.data.find((item) => item.product_title == title)
      );

      setRecentData(uniqueProducts);
    }
  });

  const [saveItem, setSaveItem] = useState([]);
  useEffect(() => {
    console.log(recentData);
    if (recentData.length > 0) {
      // setSaveItem(new Array(recentData.length).fill(false));
      setSaveItem(
        recentData.map((deal) =>
          deal.hasOwnProperty("wishlist_status") ? deal.wishlist_status : false
        )
      );
    }
  }, [recentData]);

  const saveDeal = (deal, index) => {
    console.log("archana", deal);
    let obj = {
      product_title: deal.product_title,
      product_category: "",
      product_image: deal.imgSrc,
      product_price: deal.price,
      product_url: deal.product_url,
      uuid_type: "deal",
      uuid: "",
      type: "ADD",
    };
    handleSaveToWishList(obj);
    console.log("savedWishListData ", savedWishListData);
    toggleSaveItem(index);
  };
  const toggleSaveItem = (index) => {
    setSaveItem((prevSaveItem) => {
      const newSaveItem = [...prevSaveItem];
      newSaveItem[index] = !newSaveItem[index];
      return newSaveItem;
    });
  };
  console.log("saveItem ", saveItem);

  // on adding list get updated list
  const addUpdateData = useSelector((state) => state.addUpdateData);
  useEffect(() => {
    console.log("addUpdateData", addUpdateData);
    if (addUpdateData) {
      dispatch(fetchList({}));
    }
  }, [addUpdateData]);
  useEffect(() => {
    dispatch(fetchList({}));
  }, []);

  const [isDelete, setIsDelete] = useState(false);
  const [productToDelete, setProductToDelete] = useState({});
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const removeDeal = (baseUrl, deal, index) => {
    console.log("delete deal ", deal);
    // let product_img = baseUrl + deal.deal_image;
    let obj = {
      product_title: deal.product_title,
      product_category: "",
      product_image: deal.product_image,
      product_price: deal.product_price,
      product_url: deal.product_url,
      uuid_type: "deal",
      uuid: deal.uuid,
      type: "REMOVE",
    };
    let productObj = {
      index: index,
      obj: obj,
    };
    setProductToDelete(productObj);
    setIsDeletePopup(true);
  };
  useEffect(() => {
    console.log("isDelete ", isDelete);
    if (isDelete == true) {
      handleSaveToWishList(productToDelete.obj);
      setIsDelete(false);
    }

    if (savedWishListData.message == "Removed from wishlist") {
      handleGetToWishList();
      toggleSaveItem(productToDelete.index);
      setIsDeletePopup(false);
    } else {
    }
  }, [isDelete, savedWishListData]);

  const { wishListData, loadingWishList, handleGetToWishList, wishListError } =
    useGetWishList();
  const { editSettingsData, getNotificationsOptions } = useEditSettings();

  const [recentDataState, setRecentDataState] = useState(recentData);
  const [isProcessed, setIsProcessed] = useState(false);
  useEffect(() => {
    handleGetToWishList();
    getNotificationsOptions({ type: "PRODUCT" });
  }, []);

  useEffect(() => {
    if (
      !isProcessed &&
      wishListData?.data?.length > 0 &&
      recentData?.length > 0
    ) {
      const updatedRecentData = recentData.map((recent) => {
        const savedMatch = wishListData.data.find(
          (saved) => saved.product_url === recent.product_url
        );
        return savedMatch ? { ...recent, ...savedMatch } : recent;
      });

      console.log("updatedRecentData", updatedRecentData);
      setSaveItem(
        updatedRecentData.map((deal) =>
          deal.hasOwnProperty("wishlist_status") ? deal.wishlist_status : false
        )
      );
      setRecentData(updatedRecentData);
      setIsProcessed(true);
    }
  }, [recentData, wishListData, isProcessed]);

  const [muteUpdate, setMuteUpdate] = useState(false);
  const [alreadySaved, setAlreadySaved] = useState(false);
  useEffect(() => {
    if (wishListData && wishListData.data.length > 0) {
      let alreadySaved = wishListData.data.find(
        (item) => item.product_title === activeProductData.product_title
      );

      setAlreadySaved(alreadySaved);

      if (alreadySaved != undefined) {
        alreadySaved != undefined && alreadySaved.is_mute_notification == 0
          ? setMuteUpdate(true)
          : setMuteUpdate(false);
        if (alreadySaved.notification_option_id)
          localStorage.setItem(
            "notificationId",
            JSON.stringify(alreadySaved.notification_option_id)
          );

        // const productListIds =
        //   alreadySaved.list_id != "" ? alreadySaved.list_id : "";
        // console.log("alreadySaved", productListIds);
        // onListSelect(productListIds);

        // Retrieve existing list from local storage and parse it
        // const existingList = new Set(
        //   JSON.parse(localStorage.getItem("selectedLists")) || []
        // );

        // Add only those product list ids that are not already present in existing list and are not null or undefined
        // productListIds.forEach((id) => {
        //   if (id !== null && id !== undefined) {
        //     console.log("existingList", id);
        //     existingList.add(id);
        //     return [...existingList];
        //   }
        // });
        //  console.log("existingList", productListIds);
        // Convert set back to array to maintain order (if needed)
        //const newList = [...productListIds];
        //console.log("newList", newList);
        // Save the filtered list back to local storage
        //  localStorage.setItem("selectedLists", JSON.stringify(newList));
      }
    }
  }, [wishListData]);

  useEffect(() => {
    if (addUpdateNotificationData) {
      handleGetToWishList();
    }
  }, [addUpdateNotificationData]);

  const updateMutification = () => {
    // setMuteUpdate(true);
    updateNotifcation({
      product_url: alreadySaved.product_url,
      notification_id: localStorage.getItem("notificationId")
        ? JSON.parse(localStorage.getItem("notificationId"))
        : "",
      is_mute_notification: !alreadySaved.is_mute_notification * 1,
      type: "SYSTEM",
    });
  };

  // edit section
  const [editBox, setEditBox] = useState(false);
  const [createdList, setCreateList] = useState([]);

  useEffect(() => {
    // Set background colors based on saved selected lists and fetched data
    if (fetchListData && fetchListData.data) {
      const allLists = fetchListData.data;

      let myList = allLists.filter((list) => list.created_by != null);
      setCreateList(myList);
      console.log("createdList ", createdList);
    }
  }, [fetchListData]);

  const closeEditListBox = () => {
    setEditBox(false);
  };

  const openEditListBox = () => {
    dispatch(fetchList({}));
    setEditBox(true);
  };

  // const [loading, setLoading] = useState(true);
  // const [titleLoading, setTitleLoading] = useState(false);
  // const [imageLoading, setImageLoading] = useState(false);

  // useEffect(() => {
  //   // Simulate a delay to check for the data
  //   const dataCheckTimeout = setTimeout(() => {
  //     if (!singleProduct) {
  //       setLoading(true); // Show infinite loading if no data after 2 seconds
  //     } else {
  //       setLoading(false); // Stop loading if data is received within 2 seconds
  //       if (singleProduct.imgSrc && !singleProduct.product_title) {
  //         setTitleLoading(true);
  //         setTimeout(() => {
  //           setTitleLoading(false);
  //         }, 3000); // Simulate title loading for 3 seconds
  //       } else if (!singleProduct.imgSrc && singleProduct.product_title) {
  //         setImageLoading(true);
  //         setTimeout(() => {
  //           setImageLoading(false);
  //         }, 3000); // Simulate image loading for 3 seconds
  //       }
  //     }
  //   }, 2000);

  //   return () => clearTimeout(dataCheckTimeout);
  // }, [singleProduct]);

  return (
    <>
      <>
        {/* {isCheckoutMode == "checkout" ? ( */}
        {isCheckoutMode == "" || isCheckoutMode == "checkout" ? (
          // {/* {false ? ( */}
          <>
            <div
              className="addContainer"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <ul className="addInfo">
                <i
                  class="fa-regular fa-heart"
                  style={{
                    verticalAlign: "middle",
                    minWidth: "45PX",
                    minHeight: "45PX",
                    maxWidth: "45PX",
                    maxHeight: "45PX",
                  }}
                ></i>
                <li>
                  <p>Stuff worth saving goes here</p>
                </li>
                <li>
                  <p>Not sure where to start?</p>
                </li>
                <li
                  onClick={() => {
                    setShowSavePoint(true);
                  }}
                >
                  <p>see how to save</p>
                </li>
              </ul>
              {/* line */}
              <h3>Recently Viewed</h3>
              {/* <RecentlyViewedCard
              recentData={recentData}
              getProductUrl={getProductUrl}
              saveProduct={saveProduct}
              addRemoveSavedProduct={addRemoveSavedProduct}
            /> */}
              <ul className="recentlyViewed">
                {recentData.slice(0, 10).map((product, index) => (
                  <li key={index}>
                    <img
                      src={product.imgSrc}
                      alt={product.title}
                      class="images-size"
                      onClick={() => getProductUrl(product.product_url)}
                    />
                    <div
                      className="productDetail"
                      onClick={() => getProductUrl(product.product_url)}
                      style={{ background: "white" }}
                    >
                      <div class="price">
                        <span>{product.price}</span> <span class="line">0</span>
                      </div>
                      <div>
                        <p class="title">{product.product_title}</p>
                      </div>
                    </div>
                    <>
                      <span
                        className="discount saveDeal"
                        onClick={() => {
                          removeDeal(product.product_url, product, index);
                        }}
                        style={{
                          display: saveItem[index] ? "block" : "none",
                          marginLeft: "-20px",
                          top: "56px",
                        }}
                      >
                        <i
                          className="fa-solid fa-heart"
                          style={{
                            width: "57px",
                            position: "absolute",
                            marginTop: "-49px",
                            left: "71%",
                            color: "#9D4EDD",
                            fontSize: "18px",
                          }}
                        ></i>
                      </span>
                      <span
                        className="discount saveDeal"
                        onClick={() => {
                          saveDeal(product, index);
                        }}
                        style={{
                          display: !saveItem[index] ? "block" : "none",
                          marginLeft: "-20px",
                          top: "56px",
                        }}
                      >
                        <i
                          className="fa-regular fa-heart"
                          style={{
                            width: "57px",
                            position: "absolute",
                            marginTop: "-49px",
                            left: "71%",
                            color: "#9D4EDD",
                            fontSize: "18px",
                          }}
                        ></i>
                      </span>
                    </>
                  </li>
                ))}
              </ul>
              {/* {true ? ( */}
              //{" "}
              {showSavePoint ? (
                <div
                  className="filterOverlay"
                  style={{ display: "flex", height: "100vh" }}
                >
                  <div className="itemSavingPoints">
                    <i
                      class="fa-regular fa-heart"
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "10px",
                        verticalAlign: "middle",
                        minWidth: "30px",
                        minHeight: "30px",
                        maxWidth: "30px",
                        maxHeight: "30px",
                      }}
                    ></i>
                    <h3 style={{ fontSize: "24px" }}>
                      Save your favorite items
                    </h3>
                    <div
                      onClick={() => {
                        setShowSavePoint(false);
                      }}
                    >
                      <i
                        class="fa fa-close"
                        aria-hidden="true"
                        style={{
                          fontSize: "25px",
                          float: "right",
                          position: "relative",
                          top: "-33px",
                          right: "0",
                          fontSize: "20px",
                          fontWeight: "200",
                          cursor: "pointer",
                        }}
                      ></i>
                    </div>
                    <ul>
                      <li> Go to a specific item on a retailer’s site </li>
                      <li> Deals2Buy extension will pop </li>
                      <li> Save item to your wishlist </li>
                      <li> Sit back and wait for smart updates </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            {isDeletePopup == true ? (
              <DeleteSavedDeals
                setIsDelete={setIsDelete}
                setIsDeletePopup={setIsDeletePopup}
                message={"product"}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            {/* {true ? ( */}
            {!itemSaved ? (
              <>
                <div
                  className="addContainer"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "initial",
                    paddingBottom: "80px",
                  }}
                >
                  {/* <ul
                        style={{
                          display: "flex",
                          width: "100%",
                          background: "",
                          borderRadius: "10px 0 0 10px",
                        }}
                      >
                        <li
                          style={{
                            display: "flex",
                            flex: 1,
                            cursor: "pointer",
                            height: "130px",
                            // marginBottom: '35px'
                          }}
                        >
                          {imageLoading ? (
                            <Skeleton
                              height={130}
                              width={115}
                              src={""}
                              alt=""
                              className="images_size"
                            />
                          ) : singleProduct.imgSrc ? (
                            <img
                              src={singleProduct.imgSrc}
                              alt={singleProduct.title}
                              class="images-size"
                              style={{
                                borderRadius: "10px",
                                minWidth: "115px",
                                background: "white",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                borderRadius: "10px",
                                minWidth: "115px",
                                background: "white",
                              }}
                            ></div>
                          )}
                          <div
                            className="productDetail"
                            style={{
                              justifyContent: "flexStart",
                              backgroundColor: "transparent",
                            }}
                          >
                            <div>
                              <p
                                class="title"
                                style={{
                                  textTransform: "uppercase",
                                  fontWeight: "600",
                                }}
                              >
                                {firstWord}
                              </p>
                            </div>
                            {titleLoading ? (
                              <Skeleton height={10} width={150} />
                            ) : (
                              <div>
                                <p
                                  class="title"
                                  style={{
                                    whiteSpace: "normal",
                                    // textTransform: "uppercase",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    WebkitLineClamp: "2",
                                  }}
                                >
                                  {singleProduct.product_title}
                                </p>
                              </div>
                            )}
                            <div
                              class="price"
                              style={{
                                lineHeight: "28px",
                                margin: " 0",
                                marginTop: "10px",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "black",
                              }}
                            >
                              <span>{singleProduct.price}</span>
                              //{ <span class="line">0</span> }
                            </div>
                          </div>
                        </li>
                      </ul> */}

                  <SingleProductDisplay singleProduct={singleProduct} />
                  <ul className="itemDeals">
                    <li onClick={() => setItemInfoSection("0")}>Manage Item</li>
                    <li
                      style={{
                        color: "rgb(158, 154, 154)",
                        pointerEvents: "none",
                      }}
                    >
                      Compare offers
                    </li>
                    <li
                      style={{
                        color: "rgb(158, 154, 154)",
                        pointerEvents: "none",
                      }}
                    >
                      Price trend
                    </li>
                  </ul>
                  <div
                    className="listContainer"
                    style={{
                      display: itemInfoSection == "0" ? "flex" : "none",
                    }}
                  >
                    <ProductListContainer
                      list={suggestedList}
                      onListSelect={onListSelect}
                      openCreatNewListBox={openCreatNewListBox}
                      title="suggestedList"
                      createdList={setCreateList}
                      setEditBox={setEditBox}
                      section={"add"}
                    />
                    {openCreateBox ? (
                      <CreateNewList
                        updateProductList={updateProductList}
                        closeCreatList={closeCreatListBox}
                      />
                    ) : (
                      <></>
                    )}

                    {editBox == true ? (
                      <div
                        className="filterOverlay"
                        style={{
                          display: editBox ? "flex" : "none",
                          height: "100vh",
                        }}
                      >
                        <EditList
                          editListData={saveList}
                          listData={createdList}
                          closeEditListBox={closeEditListBox}
                          height={"auto"}
                          openEditListBox={openEditListBox}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <p>My List</p>
                <ProductListContainer
                  saveList={saveList}
                  onListSelect={onListSelect}
                  openCreatNewListBox={openCreatNewListBox}
                  title="myList"
                />
                {openCreateBox ? (
                  <CreateNewList
                    updateProductList={updateProductList}
                    closeCreatList={closeCreatListBox}
                  />
                ) : (
                  <></>
                )} */}

                    <hr style={{ marginBottom: "10px" }} />

                    <h3>Notify when</h3>
                    <ProductListContainer
                      saveList={editSettingsData}
                      onListSelect={onListSelect}
                      openCreatNewListBox={addUpdateNotification}
                      title="notificationOptions"
                      disabled={muteUpdate}
                      createdList={setCreateList}
                      section={"add"}

                      //  setEditBox={setEditBox}
                    />
                    <span
                      onClick={updateMutification}
                      style={{
                        marginBottom: "15px",
                        marginTop: "-10px",
                        fontSize: "12px",
                        display: "flex",
                        fontWeight: "600",
                        textDecoration: "underline",
                        lineHeight: "11px",
                        gap: "3px",
                        cursor: "pointer",
                        display:
                          alreadySaved != undefined && muteUpdate == true
                            ? "flex"
                            : "none",
                      }}
                    >
                      <i
                        class="fa fa-bell"
                        aria-hidden="true"
                        style={{ display: "block" }}
                      ></i>
                      <span>Unmute this item</span>
                    </span>

                    <span
                      onClick={updateMutification}
                      style={{
                        marginTop: "-10px",
                        fontSize: "12px",
                        display: "flex",
                        fontWeight: "600",
                        textDecoration: "underline",
                        lineHeight: "11px",
                        gap: "3px",
                        cursor: "pointer",
                        display:
                          alreadySaved != undefined && muteUpdate == false
                            ? "block"
                            : "none",
                      }}
                    >
                      <i class="fa-solid fa-bell-slash" aria-hidden="true"></i>
                      <span>Mute this item</span>
                    </span>

                    {/* <h3>Color</h3> */}
                    {/* <ProductListContainer /> */}
                  </div>
                  <div
                    className="listContainer"
                    style={{
                      display: itemInfoSection == "1" ? "flex" : "none",
                    }}
                  >
                    two
                  </div>
                  <div
                    className="listContainer"
                    style={{
                      display: itemInfoSection == "2" ? "flex" : "none",
                    }}
                  >
                    three
                  </div>
                  {/* <div
                        className="saveButtonContainer"
                        style={{
                          position: "sticky",
                          bottom: 0,
                          width: "100%",
                          backgroundColor: "inherit",
                          padding: "20px 0",
                          zIndex: 1000,
                        }}
                      >
                        <button
                          id="saveProduct"
                          onClick={() => {
                            saveProduct(singleProduct);
                          }}
                          style={{
                            all: "unset",
                            boxSizing: "border-box",
                            cursor: "pointer",
                            display: "flex",
                            position: "relative",
                            alignItems: "center",
                            textAlign: "center",
                            justifyContent: "center",
                            transition: "0.2s",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "normal",
                            width: "calc(100% - 46px)", // Adjust width to account for padding
                            height: "40px",
                            pointerEvents: "auto",
                            color: "#fbfbf8 !important",
                            background: "rgb(254, 138, 67, 1)",
                            borderColor: "#1b1c1d",
                            border: "none !important",
                            borderRadius: "100px",
                            padding: "0 20px",
                            margin: "0 23px", // Center the button
                          }}
                        >
                          {alreadySaved ? "Update" : "Save"}
                        </button>
                      </div> */}
                </div>
                {/* <div
                      className="applyClearFilter"
                      style={{
                        bottom: "52px",
                        position: "fixed",
                        height: "99px",
                        background: "inherit",
                        padding: "0px",
                      }}
                    >
                      <button
                        id="saveProduct"
                        onClick={() => {
                          saveProduct(singleProduct);
                        }}
                        style={{
                          // bottom: "80px",
                          position: "fixed",
                          minWidth: "87%",
                          left: "23px",
                          // bottom: "84px",
                        }}
                      >
                        {alreadySaved ? "Update" : "Save"}
                      </button>
                    </div> */}
                <div className="stickyButtonContainer">
                  <button
                    id="saveProduct"
                    onClick={() => {
                      saveProduct(singleProduct);
                    }}
                  >
                    {alreadySaved ? "Update" : "Save"}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="itemSavedSection">
                  <i class="fa-solid fa-check"></i>
                  <h3>
                    {" "}
                    {alreadySaved != undefined
                      ? " Item Updated"
                      : " Item Saved"}{" "}
                  </h3>
                </div>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

export default AddComponent;
