// useToGetFavStores.js

import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductList } from "./actions";

const useGetDeals = () => {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);

  const getDealsData = useCallback(
    (domain) => {
      console.log("productList212", domain);
      dispatch(fetchProductList({ store_url: domain }));
    },
    [dispatch]
  );

  return { productList, getDealsData };
};

export default useGetDeals;
