import React, { useState, useRef, useEffect } from 'react';

const Slider = ({ items, renderItem, title, top, right, widthOfItem }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const itemRef = useRef(null);
  const [itemWidth, setItemWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    const updateDimensions = () => {
      if (itemRef.current) {
        setItemWidth(itemRef.current.offsetWidth + 10); // item width including the gap
      }
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (containerWidth > 0 && itemWidth > 0) {
      if (widthOfItem !== undefined) {
        const maxIndexValue = items.length - 1;
        setMaxIndex(maxIndexValue);
      } else {
        const maxIndexValue = Math.ceil((items.length * itemWidth - containerWidth) / itemWidth);
        setMaxIndex(maxIndexValue);
      }
    }
  }, [containerWidth, itemWidth, items.length, widthOfItem]);

  const slideLeft = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const slideRight = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
  };

  useEffect(() => {
    // Reset currentIndex if items change
    setCurrentIndex(0);
  }, [items]);

  return (
    <div className="slider-container" ref={containerRef} style={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
      {title && <h3>{title}</h3>}
      <div className="slider-content" style={{ display: 'flex', transition: 'transform 0.3s ease', transform: `translateX(-${currentIndex * itemWidth}px)` }}>
        {items.map((item, index) => (
          <div
            key={index}
            className="slider-item"
            ref={index === 0 ? itemRef : null}
            style={{
              flex: '0 0 auto',
              marginRight: index < items.length - 1 ? '10px' : '0',
              boxSizing: 'border-box',
              whiteSpace: 'nowrap',
            }}
          >
            {renderItem(item, index)}
          </div>
        ))}
      </div>
      <div className='arrow' style={{ position: 'absolute', top: top ? top : '-20%', right: right ? right : '-10px', display: 'flex', gap: '10px',  
        paddingLeft: '7px',
        width: '50px' }}>
        <button
          onClick={slideLeft}
          disabled={currentIndex === 0}
          style={{
            cursor: currentIndex === 0 ? 'not-allowed' : 'pointer',
            color: currentIndex === 0 ? '#c8c3c3' : 'black',
            border: 'none',
            background: 'transparent',
            fontSize: '20px'
          }}
        >
          &lt;
        </button>
        <button
          onClick={slideRight}
          disabled={currentIndex >= maxIndex}
          style={{
            cursor: currentIndex >= maxIndex ? 'not-allowed' : 'pointer',
            color: currentIndex >= maxIndex ? '#c8c3c3' : 'black',
            border: 'none',
            background: 'transparent',
            fontSize: '20px'
          }}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Slider;
