import React, { useEffect, useState } from "react";
import CouponsList from "./CouponsList";
import SearchInput from "./SearchInput";
import FavoriteStoresList from "./FavoriteStoresComponent";
// import { useLocation } from "react-router-dom";
import { useStoreList, useFavStoreList } from "./SaveWishListHook";
import { useFavStoreCoupons } from "./useFavStoreCouponsHook";

const SeeAllComponent = ({
  coupons,
  activeTab,
  title,
  domain,
  copyToClipboard,
}) => {
  const { getStoreListData } = useStoreList();
  const { fetchFavStoreData } = useFavStoreList();

  // console.log("storeName ", storeName);
  const [searchText, setSearchText] = useState("");
  const [firstLetter, setfirstLetter] = useState("");
  const [showList, setShowList] = useState(false);
  const { fetchFavStoreCouponsData, fetchstoreCoupons } = useFavStoreCoupons();
  const [couponsList, setCouponsList] = useState(coupons);

  useEffect(() => {
    setfirstLetter(searchText);
  }, [searchText]);
  const onSearchChange = (e) => {
    setSearchText(e.target.value);
    setShowList(true);
  };
  useEffect(() => {
    setShowList(true);
  }, [searchText]);
  useEffect(() => {
    setCouponsList(coupons);
    console.log("couponsList ", couponsList);
  }, [coupons]);
  // console.log(title)

  //   useEffect(()=>{
  //     if(fetchFavStoreCouponsData != undefined && fetchFavStoreCouponsData.store_coupons != undefined){
  //     console.log(fetchFavStoreCouponsData.store_coupons)
  // setCouponsList(fetchFavStoreCouponsData.store_coupons)}
  //   },[fetchFavStoreCouponsData])

  const [websitelist, setwebsitelist] = useState([]);
  const [favStorelist, setFavStorelist] = useState([]);
  // const location = useLocation();
  // const { coupons } = location.state || { coupons: [],  };

  console.log("domain ", domain);
  useEffect(() => {
    setwebsitelist(getStoreListData.data);
  }, [getStoreListData]);

  useEffect(() => {
    setFavStorelist(fetchFavStoreData.data);
  }, [fetchFavStoreData]);
  return (
    <>
      <div style={{ padding: "20px" }}>
        {/* {searchText != '' ?<StoreListComponent/> :''} */}
        {showList ? (
          <FavoriteStoresList
            storeList={websitelist}
            favouriteStoreList={favStorelist}
            domain={domain}
            fetchFavStoresData={fetchFavStoreData}
            height={"580px"}
            animation={"none"}
            borderRadius={"0"}
            overlay={false}
            heading={"none"}
            top={"51px"}
            submitBtn={"none"}
            stars={"none"}
            storeBox={false}
            activeTab={activeTab}
            title={title}
            backgroundColor={"#F7F8F9"}
            seeAll={false}
            copyToClipboard={copyToClipboard}
            //  getFavStoreCoupons={getFavStoreCoupons}
            couponsList={couponsList}
          />
        ) : (
          <CouponsList
            coupons={couponsList}
            domain={domain}
            copyToClipboard={copyToClipboard}
            seeAll={false}
          />
        )}
      </div>
    </>
  );
};

export default SeeAllComponent;
