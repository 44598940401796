// sagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_DATA,
  fetchDataSuccess,
  fetchDataFailure,
  FETCH_PRODUCT_LIST,
  fetchProductListSuccess,
  FETCH_SIMILAR_PRODUCT,
  fetchProductListFailure,
  fetchSimilarProductSuccess,
  fetchSimilarProductFailure,
  FETCH_COOKIE,
  fetchCookieSuccess,
  fetchCookieFailure,
  FETCH_UNIQUEID,
  fetchUniqueIdSuccess,
  fetchUniqueIdFailure,
  fetchWishListFailure,
  FETCH_WISHLIST,
  fetchWishListSuccess,
  saveWishListSuccess,
  saveWishListFailure,
  SAVE_WISHLIST,
  getStoresSuccess,
  getStoresFailure,
  GET_STORELIST,
  addFavStoreSuccess,
  addFavStoreFailure,
  ADD_FAVSTORE,
  createListSuccess,
  createListFailure,
  CREATE_LIST,
  fetchListSuccess,
  fetchListFailure,
  FETCH_LIST,
  fetchFavStoresFailure,
  fetchFavStoresSuccess,
  FETCH_FAVSTORE,
  fetchFavStoreCouponsSuccess,
  fetchFavStoreCouponsFailure,
  FETCH_FAVSTORE_COUPONS,
  removeFavStoreSuccess,
  removeFavStoreFailure,
  REMOVE_FAV_STORE,
  deleteListSuccess,
  deleteListFailure,
  DELETE_LIST,
  ADDUPDATE_LIST,
  addUpdateListSuccess,
  addUpdateListFailure,
  ADD_TO_LIST,
  addToListSuccess,
  addToListFailure,
  ADD_UPDATE_NOTIFICATION,
  addUpdateNotificationSuccess,
  addUpdateNotificationFailure,
  deleteProductSuccess,
  DELETE_PRODUCTS,
  getSimilarProductsFailure,
  getSimilarProductsSuccess,
  markAsReadSuccess,
  markAsReadFailure,
  getNotificationsSuccess,
  getNotificationsFailure,
  MARK_AS_READ,
  GET_NOTIFICATIONS,
  editSettingsSuccess,
  editSettingsFailure,
  priceEvolutionSuccess,
  priceEvolutionFailure,
  EDIT_SETTINGS,
  PRICE_EVOLUTION,
  GET_SIMILAR_PRODUCTS,
  DELETE_NOTIFICATION,
  deleteProductFailure,
  PRODUCT_REVIEWS,
  getProductReviewsSuccess,
  getProductReviewsFailure,
} from "./actions";
import {
  getCouponCodes,
  getProductList,
  getSimilarProduct,
  getCookie,
  getUniqueId,
  saveToWishList,
  fetchWishList,
  fetchStoreList,
  addingFavStore,
  createList,
  getList,
  fetchFavStore,
  getfavStoreCoupons,
  deleteFavStore,
  deleteList,
  addUpdateList,
  addToList,
  addUpdateNotifcation,
  deleteProduct,
  priceEvolution,
  editSettings,
  getNotificationsData,
  markAsReadNotification,
  getSimilarItems,
  deleteNotification,
  getProductReviews,
} from "./CouponApi"; // Your API call function

function* fetchDataSaga(action) {
  try {
    console.log(action.payload);

    const response = yield call(getCouponCodes, action.payload); // Replace 'apiCall' with your API function
    console.log(response);
    console.log(response.data.related_coupons);
    yield put(fetchDataSuccess(response.data));
  } catch (error) {
    yield put(fetchDataFailure(error));
  }
}

function* fetchProductList(action) {
  console.log("responce", action);
  try {
    const response = yield call(getProductList, action.payload.store_url);
    console.log("responce1", response);
    yield put(fetchProductListSuccess(response.data));
  } catch (error) {
    yield put(fetchProductListFailure(error));
  }
}

function* fetchSimilarProduct(action) {
  try {
    console.log(action);
    console.log(action.payload);
    const response = yield call(getSimilarProduct, action.payload);
    console.log(response);
    yield put(fetchSimilarProductSuccess(response.data));
  } catch (error) {
    yield put(fetchSimilarProductFailure(error));
  }
}

function* fetchCookie(action) {
  try {
    console.log(action);
    const response = yield call(getCookie, action.payload);
    console.log(response);
    yield put(fetchCookieSuccess(response.data));
  } catch (error) {
    yield put(fetchCookieFailure(error));
  }
}
function* fetchUniqueId(action) {
  try {
    console.log(action);
    const response = yield call(getUniqueId, action.payload);
    console.log(response);
    yield put(fetchUniqueIdSuccess(response.data));
  } catch (error) {
    yield put(fetchUniqueIdFailure(error));
  }
}
function* saveWishList(action) {
  try {
    console.log(action);
    const response = yield call(saveToWishList, action.payload);
    console.log(response);
    yield put(saveWishListSuccess(response.data));
  } catch (error) {
    yield put(saveWishListFailure(error));
  }
}
function* getWishList(action) {
  try {
    console.log(action);
    const response = yield call(fetchWishList, action.payload);
    console.log(response);
    yield put(fetchWishListSuccess(response.data));
  } catch (error) {
    yield put(fetchWishListFailure(error));
  }
}

// getstore list
function* getStoreList(action) {
  try {
    console.log(action);
    const response = yield call(fetchStoreList, action.payload);
    console.log(response);
    yield put(getStoresSuccess(response.data));
  } catch (error) {
    yield put(getStoresFailure(error));
  }
}

// addFav store
function* saveFavStore(action) {
  try {
    console.log(action);
    const response = yield call(addingFavStore, action.payload);
    console.log(response);
    yield put(addFavStoreSuccess(response.data));
  } catch (error) {
    yield put(addFavStoreFailure(error));
  }
}

// fetchFavStore
function* fetchFavStores(action) {
  try {
    console.log(action);
    const response = yield call(fetchFavStore, action.payload);
    console.log(response);
    yield put(fetchFavStoresSuccess(response.data));
  } catch (error) {
    yield put(fetchFavStoresFailure(error));
  }
}

// create list
function* creatingNewList(action) {
  try {
    console.log(action);
    const response = yield call(createList, action.payload);
    console.log(response);
    yield put(createListSuccess(response.data));
  } catch (error) {
    yield put(createListFailure(error));
  }
}

// get list
function* fetchingList(action) {
  try {
    console.log(action);
    const response = yield call(getList, action.payload);
    console.log(response);
    yield put(fetchListSuccess(response.data));
  } catch (error) {
    yield put(fetchListFailure(error));
  }
}
// get fav store coupons
function* fetchFavStoreCoupons(action) {
  try {
    console.log(action);
    const response = yield call(getfavStoreCoupons, action.payload);
    console.log(response);
    yield put(fetchFavStoreCouponsSuccess(response.data));
  } catch (error) {
    yield put(fetchFavStoreCouponsFailure(error));
  }
}

// removeFav store
function* removeFavStores(action) {
  try {
    console.log(action);
    const response = yield call(deleteFavStore, action.payload);
    console.log(response);
    yield put(removeFavStoreSuccess(response.data));
  } catch (error) {
    yield put(removeFavStoreFailure(error));
  }
}

// removeFav store
function* deleteSingleList(action) {
  try {
    console.log(action);
    const response = yield call(deleteList, action.payload);
    console.log(response);
    yield put(deleteListSuccess(response.data));
  } catch (error) {
    yield put(deleteListFailure(error));
  }
}

// removeFav store
function* addUpdateListFunction(action) {
  try {
    console.log(action);
    const response = yield call(addUpdateList, action.payload);
    console.log(response);
    yield put(addUpdateListSuccess(response.data));
  } catch (error) {
    yield put(addUpdateListFailure(error));
  }
}

// removeFav store
function* addToListFunction(action) {
  try {
    console.log(action);
    const response = yield call(addToList, action.payload);
    console.log(response);
    yield put(addToListSuccess(response.data));
  } catch (error) {
    yield put(addToListFailure(error));
  }
}

// add update notifcatioin
function* updateNotifcation(action) {
  try {
    console.log(action);
    const response = yield call(addUpdateNotifcation, action.payload);
    console.log(response);
    yield put(addUpdateNotificationSuccess(response.data));
  } catch (error) {
    yield put(addUpdateNotificationFailure(error));
  }
}

// add update notifcatioin
function* deleteProductFunction(action) {
  try {
    console.log(action);
    const response = yield call(deleteProduct, action.payload);
    console.log(response);
    yield put(deleteProductSuccess(response.data));
  } catch (error) {
    yield put(deleteListFailure(error));
  }
}

// similar products
function* getSimilarItemsData(action) {
  try {
    console.log(action);
    const response = yield call(getSimilarItems, action.payload);
    console.log(response);
    yield put(getSimilarProductsSuccess(response.data));
  } catch (error) {
    yield put(getSimilarProductsFailure(error));
  }
}

// markAsReadNotification
function* markAsReadNotifications(action) {
  try {
    console.log(action);
    const response = yield call(markAsReadNotification, action.payload);
    console.log(response);
    yield put(markAsReadSuccess(response.data));
  } catch (error) {
    yield put(markAsReadFailure(error));
  }
}

// get notifcation for all
function* getNotifications(action) {
  try {
    console.log(action);
    const response = yield call(getNotificationsData, action.payload);
    console.log(response);
    yield put(getNotificationsSuccess(response.data));
  } catch (error) {
    yield put(getNotificationsFailure(error));
  }
}

// edit settings
function* editSettingsData(action) {
  try {
    console.log(action);
    const response = yield call(editSettings, action.payload);
    console.log(response);
    yield put(editSettingsSuccess(response.data));
  } catch (error) {
    yield put(editSettingsFailure(error));
  }
}

// priceEvolution

function* priceEvolutionData(action) {
  try {
    console.log(action);
    const response = yield call(priceEvolution, action.payload);
    console.log(response);
    yield put(priceEvolutionSuccess(response.data));
  } catch (error) {
    yield put(priceEvolutionFailure(error));
  }
}

// delete product notification

function* deleteProductNotification(action) {
  try {
    console.log(action);
    const response = yield call(deleteNotification, action.payload);
    console.log(response);
    yield put(deleteProductSuccess(response.data));
  } catch (error) {
    yield put(deleteProductFailure(error));
  }
}

function* productReviewsFunction(action) {
  try {
    console.log(action);
    const response = yield call(getProductReviews, action.payload);
    console.log(response);
    yield put(getProductReviewsSuccess(response.data));
  } catch (error) {
    yield put(getProductReviewsFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_DATA, fetchDataSaga);
  yield takeLatest(FETCH_PRODUCT_LIST, fetchProductList);
  yield takeLatest(FETCH_SIMILAR_PRODUCT, fetchSimilarProduct);
  yield takeLatest(FETCH_COOKIE, fetchCookie);
  yield takeLatest(FETCH_UNIQUEID, fetchUniqueId);
  yield takeLatest(SAVE_WISHLIST, saveWishList);
  yield takeLatest(FETCH_WISHLIST, getWishList);
  yield takeLatest(GET_STORELIST, getStoreList);
  yield takeLatest(ADD_FAVSTORE, saveFavStore);
  yield takeLatest(CREATE_LIST, creatingNewList);
  yield takeLatest(FETCH_LIST, fetchingList);
  yield takeLatest(FETCH_FAVSTORE, fetchFavStores);
  yield takeLatest(FETCH_FAVSTORE_COUPONS, fetchFavStoreCoupons);
  yield takeLatest(REMOVE_FAV_STORE, removeFavStores);
  yield takeLatest(DELETE_LIST, deleteSingleList);
  yield takeLatest(ADDUPDATE_LIST, addUpdateListFunction);
  yield takeLatest(ADD_TO_LIST, addToListFunction);
  yield takeLatest(ADD_UPDATE_NOTIFICATION, updateNotifcation);
  yield takeLatest(DELETE_PRODUCTS, deleteProductFunction);
  yield takeLatest(MARK_AS_READ, markAsReadNotifications);
  yield takeLatest(GET_NOTIFICATIONS, getNotifications);
  yield takeLatest(EDIT_SETTINGS, editSettingsData);
  yield takeLatest(PRICE_EVOLUTION, priceEvolutionData);
  yield takeLatest(GET_SIMILAR_PRODUCTS, getSimilarItemsData);
  yield takeLatest(DELETE_NOTIFICATION, deleteProductNotification);
  yield takeLatest(PRODUCT_REVIEWS, productReviewsFunction);
}
