import React, { useState } from "react";
import RecentlyViewedCard from "./recentlyViewedCard";
import { useUpdateNotification } from "./SaveWishListHook";
import { useDeleteNotification } from "./ThreeDotsApiCallsHook";

const NotificationComponent = ({
  itemUpdates,
  getNotifcationProduct,
  domain,
}) => {
  const [threeDotsClicked, setThreeDotsClicked] = useState(false);
  const [notifyProduct, setNotifyProduct] = useState();
  const { addUpdateNotificationData, updateNotifcation } =
    useUpdateNotification();
  const { deleteNotificationData, deleteSingleProductNotification } =
    useDeleteNotification();

  const threeDotsEvent = (product) => {
    setThreeDotsClicked(!threeDotsClicked);
    getNotifcationProduct(product);
    console.log("getNotifcationProduct ", product);
    setNotifyProduct(product);
  };

  const setMuteNotifcationFunction = (muteNotification) => {
    const newMuteNotification = !muteNotification * 1;

    console.log("newMuteNotification ", muteNotification);
    console.log("newMuteNotification ", newMuteNotification);
    updateNotifcation({
      product_url: notifyProduct.product_url,
      is_mute_notification: newMuteNotification,
      notification_id: localStorage.getItem("notificationId")
        ? JSON.parse(localStorage.getItem("notificationId"))
        : "",
      // is_email_notification_enabled:0,
      // is_system_notification_enabled:1,
      type: "SYSTEM",
    });
    setThreeDotsClicked(false);
  };
  return (
    <>
      <h3>This Week</h3>

      <RecentlyViewedCard
        border="10px"
        threeDots={true}
        threeDotsClicked={threeDotsEvent}
        domain={domain}
      />

      <h3>This Month</h3>

      <RecentlyViewedCard
        border="10px"
        threeDots={true}
        threeDotsClicked={threeDotsEvent}
        domain={domain}
      />

      {threeDotsClicked ? (
        <div
          className="filterOverlay"
          style={{ display: "flex", height: "100vh" }}
        >
          <div className="noticationPopup" style={{ maxHeight: "426px" }}>
            <div
              onClick={() => {
                setThreeDotsClicked(!threeDotsClicked);
              }}
            >
              <i
                class="fa fa-close"
                aria-hidden="true"
                style={{
                  fontSize: "25px",
                  float: "right",
                  position: "relative",
                  top: "12px",
                  right: "0",
                  fontSize: "20px",
                  fontWeight: "200",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            <div
              style={{
                padding: "20px",
                // background: " #FBFBF8",
                background: "#fff",
                borderRadius: "20px 20px 0px 0px",
                width: "100%",
              }}
            >
              <RecentlyViewedCard
                border="10px"
                threeDots={false}
                threeDotsClicked={threeDotsEvent}
              />
            </div>
            <ul className="notificationList">
              <li
                onClick={() => {
                  setMuteNotifcationFunction(
                    notifyProduct.is_mute_notification
                  );
                }}
              >
                {" "}
                <i
                  class={
                    notifyProduct.is_mute_notification
                      ? "fa-solid fa-bell-slash"
                      : "fa fa-bell"
                  }
                  aria-hidden="true"
                  style={{ color: "rgb(254, 138, 67, 1)", fontSize: "20px" }}
                ></i>{" "}
                {notifyProduct.is_mute_notification ? (
                  <span>UnMute all notifications for this item</span>
                ) : (
                  <span>Mute all notifications for this item</span>
                )}{" "}
              </li>
              <li
                onClick={() => {
                  itemUpdates();
                }}
              >
                {" "}
                <i
                  class="fa fa-cog"
                  aria-hidden="true"
                  style={{ color: "rgb(254, 138, 67, 1)", fontSize: "20px" }}
                ></i>{" "}
                <span>View notification settings</span>{" "}
              </li>
              <li
                onClick={() => {
                  deleteSingleProductNotification({
                    product_url: notifyProduct.product_url,
                  });
                  setThreeDotsClicked(false);
                }}
              >
                <i
                  class="fa fa-trash"
                  aria-hidden="true"
                  style={{ color: "rgb(254, 138, 67, 1)", fontSize: "20px" }}
                ></i>{" "}
                <span>Delete notification</span>{" "}
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default NotificationComponent;
