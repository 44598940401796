// useUniqueIdHook.js

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUniqueId} from './actions';


const useUniqueId = () => {
  const dispatch = useDispatch();
  const uniqueIdData = useSelector((state) => state.uniqueIdData);

  useEffect(() => {
    dispatch(fetchUniqueId({}));
  }, [dispatch]);

  return {
    uniqueIdData,
  };
};

export default useUniqueId;
