import React from "react";

const SearchInput = ({ searchText, onSearchChange, onKeyPress,height,margin ,clearSearchText,inputWidth,marginIcon,searchIcon,fetchListOnSearch})=>{
    console.log(inputWidth,marginIcon)
    
    return(
        <div style={{display:'flex',width:inputWidth  ? inputWidth :''}}>
            <input
        type="text"
        placeholder="Search products..."
        value={searchText}
        onChange={onSearchChange}
        onKeyPress={onKeyPress}
        className='searchInput'
        style={{height: height ==undefined ?"100%":height,margin: margin ==undefined ?"0":margin}}
        
      />

<div>
<span onClick={fetchListOnSearch}    style={{display: searchText == ''?'none':'block'}}>
      {searchIcon == true ?  <i class="fa fa-search" style={{ cursor:'pointer',margin: marginIcon ? marginIcon : '25px 0px 18px -51px',position:'absolute',fontSize:'12px'}}></i>: ''}
     

      </span>
      <span onClick={clearSearchText}  style={{display: searchText == ''?'none':'block'}}>
      <i class="fa fa-close" style={{ cursor:'pointer',margin: marginIcon ? marginIcon : '22px 0px 18px -27px',position:'absolute'}}></i>
      </span>
</div>
      </div>

    )
}

export default SearchInput;