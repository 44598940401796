import React, { useEffect, useState } from "react";
import { useActiveTab } from "./ActiveTabConntext";
import { Link } from "react-router-dom";
import useGetDeals from "./ApisCallsComponent";
import { useGetNotification } from "./ThreeDotsApiCallsHook";
import { getCookieValue } from "./BuildUrl";
import logo from "../assets/logo.png";

const HeaderComponent = ({
  activeTab,
  closeSlider,
  notificationTab,
  domain,
}) => {
  // const { activeTab } = useActiveTab();
  // const { navigate } = useActiveTab();
  // getNotifications
  const [totalPoints, setTotalPoints] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // New state to check login

  const { getNotificationsData, NotificationsDataFromDerver } =
    useGetNotification();

  useEffect(() => {
    // Check if user is logged in based on a specific cookie
    const authToken = getCookieValue("phpbb3_3hnc8_u"); // Replace with the actual cookie name for login

    if (authToken > 1) {
      setIsLoggedIn(true); // Set to true if authToken exists
    } else {
      setIsLoggedIn(false); // Set to true if authToken exists
    }

    if (getNotificationsData != undefined) {
      setTotalPoints(getNotificationsData.total_points);
      setNotificationCount(getNotificationsData.unread_count);
      // setTotalPoints(500)

      try {
        // Handle the response and send a postMessage
        const parentWindow = window.parent;
        // parentWindow.postMessage({ type: 'NOTIFICATION_RESPONSE', payload: response.data }, '*');
        console.log("unread_count ", getNotificationsData.unread_count);
        parentWindow.postMessage(
          {
            action: "notificationCount",
            notificationCount: getNotificationsData.unread_count,
          },
          domain
        );
      } catch (error) {
        console.log("Error fetching notifications:", error);
        // Optionally, you can send a postMessage for errors as well
        // parentWindow.postMessage({ type: 'NOTIFICATION_ERROR', payload: error }, '*');
      }
    }
  }, [getNotificationsData]);

  // Function to repeatedly call getNotificationsData every 5 minutes
  const startNotificationPolling = () => {
    // Initial call
    NotificationsDataFromDerver();

    // Set interval to call the function every 5 minutes
    setInterval(() => {
      NotificationsDataFromDerver();
    }, 300000); // 300000 milliseconds = 5 minutes
  };

  useEffect(() => {
    startNotificationPolling();
  }, []);

  return (
    <>
      <div className="header">
        {/* Add your logo here */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="Logo" style={{ height: "30px" }} />
          {/* <h3>
            <Link to={navigate}> 
    <i
              class="fa fa-arrow-left"
              aria-hidden="true"
              style={{
                fontSize: "25px",
                float: "right",
                position: "relative",
                top: "-21px",
                right: "0",
                fontSize: "20px",
                fontWeight: "200",
                cursor: "pointer",
                display:'none'
              }}
            ></i>
    </Link>
            {activeTab}
          </h3> */}
        </div>
        <ul className="headerItem">
          {!isLoggedIn ? (
            <li style={{ textAlign: "center", display: "block" }}>
              <a
                href="https://deals2buy.in/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "white",

                  textDecoration: "none", // Remove underline
                }}
                // style={{
                //   display: "inline-block",
                //   fontSize: "12px",
                //   padding: "5px 10px",
                //   backgroundColor: "#007bff", // Button background color
                //   color: "white", // Button text color
                //   borderRadius: "5px", // Rounded corners
                //   textDecoration: "none", // Remove underline
                //   border: "none", // Remove default border
                //   cursor: "pointer", // Show pointer on hover
                // }}
              >
                <span>Login</span>
              </a>
            </li>
          ) : (
            <li>
              {/* <img src="saved-icon.png" alt="Img1" class="icon"></img> */}
              <i
                class="fa fa-get-pocket"
                style={{
                  transform: "rotate(90deg)",
                  color: "white",
                  fontSize: "25px",
                }}
              ></i>
              <span>{totalPoints}</span>
            </li>
          )}

          {/* <li>
            <a
              href="https://lannister.deals2buy.in/"
              target="_blank"
              rel="noopener noreferrer"
              style={{}}
            >
              {" "}
              <i
                class="fa fa-smile-o"
                aria-hidden="true"
                style={{ fontSize: "25px", color: "#fff" }}
              ></i>
            </a>
          </li> */}

          <li
            onClick={() => {
              notificationTab();
            }}
          >
            {/* <img src="saved-icon.png" alt="Img3" class="icon"></img> */}
            <div
              style={{
                top: "-9px",
                position: "absolute",
                height: "17px",
                width: "auto",
                borderRadius: "10px",
                display: "flex",
                transform: "translate(12px, 14px)",
                color: "orange",
                alignItems: "center",
                fontSize: "11px",
                background: "white",
                fontWeight: "600",
                padding: "4px",
              }}
            >
              {notificationCount}
            </div>
            <i
              class="fa fa-bell"
              aria-hidden="true"
              style={{ fontSize: "25px" }}
            ></i>
          </li>
          <li
            onClick={() => {
              closeSlider();
            }}
          >
            {/* <img src="saved-icon.png" alt="Img3" class="icon"></img> */}

            <i
              class="fa fa-close"
              aria-hidden="true"
              style={{ fontSize: "25px", color: "#fff" }}
            ></i>
          </li>
        </ul>
      </div>
    </>
  );
};

export default HeaderComponent;
