import React, { useEffect, useState } from 'react';
import './SliderCssComponent.css';
import NoActiveDeals from './noActiveDeals';
import { fetchData, fetchUniqueId } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { buildUrl, getStoreName, handleRedirect } from './BuildUrl';
import useUniqueId from './useUniqueIdHook';
import { useNavigate } from 'react-router-dom';
import { getDealsUrl } from './BuildUrl';

const CouponsSlider = ({ slide, title, domain, coupons, copyToClipboard, setSeeAllData, setCouponData, websitelist, favStorelist, handleSeeAllClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  const CouponsList = useSelector((state) => state.CouponData);
  const { uniqueIdData } = useUniqueId();
  const [uuid, setUuid] = useState('');
  const [seeAll, setSeeAll] = useState(true);
  const [showCouponCode, setShowCouponCode] = useState(new Array(coupons != undefined && coupons.length).fill(false));

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log('uniqueIdData ', uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  const toggleCouponCode = (index) => {
    setShowCouponCode(prevCouponCode => {
      const newCouponCode = [...prevCouponCode];
      newCouponCode[index] = !newCouponCode[index];
      return newCouponCode;
    });
  };

  const getCouponUrl = (index, url, platform, storeId) => {
    copyToClipboard(index, coupons);
    toggleCouponCode(index);

    let HTMLencodedUrl = encodeURIComponent(url);
    const dealUrl = buildUrl(uuid, HTMLencodedUrl, platform, storeId);
    const parentWindow = window.parent;
    parentWindow.postMessage({ action: 'redirectToStore', storeUrl: dealUrl, redirectTab: false }, domain);
  };

  const handleSeeAll = () => {
    handleSeeAllClick(coupons, title, copyToClipboard);
    setCouponData(coupons);
  };
  useEffect(() => {
    console.log('domain ', domain);
    dispatch(fetchData({ domain: domain }));
  }, []);

  const slideLeft = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const slideRight = () => {
    setCurrentIndex((prevIndex) => (prevIndex < coupons.length - 1 ? prevIndex + 1 : prevIndex));
  };

  return (
    <>
      {seeAll == true && coupons != undefined && coupons.length > 0 ?
        <>
          <div className="slider-container">
            <div className='sliderHeader' style={{height: '22px'}}>
              <h3>{title}</h3>
              <div className='arrow' style={{    height: '31px',
    marginTop: '10px'}}>
                {coupons.length > 1 && (
                  <button
                    onClick={slideLeft}
                    className="left-arrow"
                    disabled={currentIndex === 0}
                    style={{ cursor: currentIndex === 0 ? 'not-allowed' : 'pointer' }}
                  >
                    &lt;
                  </button>
                )}
                {coupons.length > 1 && (
                  <button
                    onClick={slideRight}
                    className="right-arrow"
                    disabled={currentIndex === coupons.length - 1}
                    style={{ cursor: currentIndex === coupons.length - 1 ? 'not-allowed' : 'pointer' }}
                  >
                    &gt;
                  </button>
                )}
              </div>
            </div>
            <div className='sliderArrow'>

            </div>
            <div
              className="slider-content"
              style={{
                display: 'flex',
                transform: `translateX(-${currentIndex * 100 / coupons.length}%)`, // Adjusted for variable number of coupons
                width: `${coupons.length * 100}%`,
              }}
            >
              {coupons.map((item, index) => (
                <div className='popularSection' key={index}>
                  <div className='couponTag'>
                    <p className='text'>Exclusive</p>
                  </div>
                  <div className='couponData' style={{ width: '918px' }}>
                    <div className="couponInfo">
                      <p className="storeName">{item.store_name}</p>
                      <div className="text">{item.coupon_title}</div>
                    </div>
                    <div className="couponInfo">
                      <div className="text">Worked {item.coupon_users_redeemed} days ago</div>
                      <div className="couponBtns">
                        <button data-alooma-section="retailer" data-alooma-source="coupons" data-alooma-element="get_coupon" onClick={() => { getCouponUrl(index, item.coupon_url, item.store_name, item.store_id) }} style={{ display: !showCouponCode[index] ? 'block' : 'none' }}>Get Coupon</button>
                        <button data-alooma-section="retailer" data-alooma-source="coupons" data-alooma-element="get_coupon" style={{ display: showCouponCode[index] ? 'block' : 'none' }}>{item.coupon_code}</button>
                        <button data-alooma-section="retailer" data-alooma-source="coupons" data-alooma-element="go_to_store" onClick={() => { getDealsUrl(item.store_url, item.store_id, domain) }}>Go to store</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {seeAll ? <span style={{ cursor: "pointer", background: 'none',  marginTop: '-6px',  fontSize: '12px' }} onClick={handleSeeAll} >See All</span> : ''}
        </>
        : <><h3>{title}</h3> <NoActiveDeals /></>}
    </>
  );
}

export default CouponsSlider;
