import React from "react";
import Skeleton from 'react-loading-skeleton';  
import 'react-loading-skeleton/dist/skeleton.css';

const AnotherCard = () => (
    <div className="another_card">
      <Skeleton height={200} width={150} className='images-size' />
      <Skeleton height={20} width={50} />
      <Skeleton height={10} width={150} />
      <Skeleton height={10} width={150} style={{ margin: '0px', padding: '0' }} />
    </div>
  );
export   const generateIterablePart = (index) => (
    <div className="items_wrapper" key={index} >
      <AnotherCard />
    </div>
  );

